.footer {
    background-color: #113b61;
    color: #fff;
    padding: 50px 0;
    
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
}

.footer-col {
    width: calc(100% / 3 - 20px);
    margin-bottom: 20px;
}

.footer-col h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.footer-col p {
    font-size: 16px;
    line-height: 1.5;
}

.footer-col ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-col li {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.footer-col a {
    color: #fff;
    text-decoration: none;
}

.footer-bottom {
    text-align: center;
}

.footer-bottom p {
    font-size: 16px;
    margin-bottom: 0;
}
