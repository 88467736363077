.testimonials {
    padding: 80px 0;
    text-align: center;
}

.testimonials h2 {
    margin-bottom: 40px;
    font-size: 40px;
}

.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #113b61;
}

.testimonial {
    background-color: #f8f8f8;
    border-radius: 8px;
    margin: 20px;
    padding: 40px;
    max-width: 350px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.testimonial-text {
    margin-bottom: 20px;
}

.testimonial-author h3 {
    margin-bottom: 8px;
}

.testimonial-author span {
    font-style: italic;
    color: #666;
}

.test_img{
    border-radius: 50%;
    height: 15vh;
}
