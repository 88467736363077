@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');
.strip{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f5f5f5;
}


.f1 {
    margin-left: 100px;
}

.f2{
    margin-right: 100px;
}

.bsf{
    margin-left: 35px;
    color: #113b61;
}

.ios{
    margin-left: 15px;
    color: #113b61;
}

.yo{
    font-size: 30px;
    margin-left: 25px;
}

.yo2{
    font-size: 30px;
    margin-left: 15px;
}
p{
    font-family: 'Kanit', sans-serif;
}

@media screen  and (max-width : 600px ){
    
    .f1{
        margin-left: 20px;
    }

    .f2{
        margin-right: 20px;
    }


}