body.active-modal {
    overflow-y: hidden;
}


.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    color: black;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.contact-us-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247,247,247);
    
  }
  
  .contact-us-container h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  button {
    background-color: #ffa500;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #3e8e41;
  }
  
  @media screen and (max-width: 768px) {
    .contact-us-container {
      padding: 30px;
    }
    
    .contact-us-container h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
    
    label {
      font-size: 16px;
      margin-bottom: 8px;
    }
    
    input,
    textarea {
      padding: 8px;
      font-size: 14px;
    }
    
    button {
      font-size: 16px;
      padding: 8px 16px;
    }

    .modal-content{
      width: 50%;
    }
  }
  