@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;

}

:root {
    --mainColor: #113b61;
    --textColor: #eee;

}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    position: fixed;
    width: 100%;
    z-index: 999;
}

nav a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    font-size: 20px;
    padding: 18px 10px;

}

nav a:hover {
    color:#ffa500;
    border-bottom: 4px solid #ffa500;
    transition: all 0.2s ease-in-out;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    display: none;
    font-size: 1.8rem;
}

header h1{
    margin-left: 100px;
}

header h1 a{
    text-decoration: none;
    color: white;
}

header div,
nav {
    display: flex;
    align-items: center;
    z-index: 999;
    justify-content: space-between;
    margin-right: 100px;
}
header > a > img {
    height: 6vh;
    margin-left: 40px;
}

@media only screen and (max-width: 1024px) {
    header .nav-btn {
        display: block;

    }

    header nav {
        position: fixed;
        top: -100vh;
        left: 0;
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 0.4s;
    }

    header .responsive_nav {
        transform: translateY(100vh);
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
    }

    header h1{
        margin-left: 0;
    }
}