.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.about-text {
    max-width: 800px;
    margin-right: 0px;

}

.about-text h2 {
    font-size: 50px;
    margin-bottom: 30px;
}

.about-text p {
    font-size: 20px;
    padding-right: 40px;
}
.about-image {
    max-width: 400px;
}

.about-image img {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .about-container {
        flex-direction: row;
    }

    .about-text {
        margin-right: 0;
       
    }
}

@media screen and (max-width: 768px){
    .about-text>h2{
        text-align: center;
    }

   .about-container{
    width: 100%;
    flex-direction: none;
   }

   .about-text>p{
    text-align: center;
    margin-left: 10px;
    font-size: 17px;
   }
}
