.download-app {
    background-color: #f7f7f7;
    padding: 50px 0;
}

.download-app-content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.download-app-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.download-app-content p {
    font-size: 18px;
    margin-bottom: 30px;
}

.download-buttons {
    display: flex;
    justify-content: center;
}

.btn-download {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 30px;
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    background-color: #007bff;
}

.btn-download:hover {
    background-color: #0056b3;
}

.btn-download i {
    font-size: 24px;
    margin-right: 10px;
}
