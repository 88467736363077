.logo-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .logo-slider img {
    max-width: 100%;
    max-height: 15vh;
    margin: 20px;
  }
  
 .lg >h2{
    font-size: 36px;
    margin-bottom: 30px;
    text-align: center;
  }